import React from "react"

import { Hero } from "../../components/blog/hero"
import { PostSidebar } from "../../components/blog/post-sidebar"
import { Breadcrumbs, BreadcrumbsItem } from "../../components/breadcrumbs"
import { Container } from "../../components/container"
import SEO from "../../components/seo"
import img1 from "./images/3-min.jpg"
import styles from "./post-grid.module.css"

const ProcSeVamNedariZhubnout = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={"Proč se vám nedaří zhubnout? | NutritionPro"}
        description={
          "Usilovně se snažíte, ale váha pořád nejde dolu? Už nevíte, co jiného vyzkoušet, aby se věci konečně daly do pohybu? Možná děláte jednu z následujících chyb."
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: "24px 0" }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Proč se vám nedaří zhubnout?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Proč se vám nedaří zhubnout?" date="08.02.2023" />
            <div>
              <p className={styles.postText}>
                Usilovně se snažíte, ale váha pořád nejde dolu? Už nevíte, co
                jiného vyzkoušet, aby se věci konečně daly do pohybu? Možná
                děláte jednu z následujících chyb.
              </p>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: "40px" }}
                alt="img1"
              />
              <h5 className={styles.postTitle}>Jíte málo</h5>
              <p className={styles.postText}>
                Znáte svůj optimální denní energetický příjem? Tedy{" "}
                <b>kolik byste toho měli za den sníst</b>? Spousta z vás si může
                myslet, že toho přes den sní akorát, reálně však můžete mít
                velmi <b>nízký přísun energie.</b> To může vést k chutím na
                sladké (nebo slané, zkrátka budete mít chuť mlsat) a následnému
                přejídání. Ve výsledku bude váš energetický příjem ještě mnohem{" "}
                <b>vyšší</b>, než je vaše optimum. Prvním krokem, abyste tuto
                problematiku vyřešili, je zjistit, proč tomu tak je. Proč jíte
                málo. Je možné, že už vás{" "}
                <b>nebaví neustále vymýšlet, co si máte uvařit.</b> Nebo třeba{" "}
                <b>nemáte čas</b> pořád chodit na nákup a následně{" "}
                <b>vyvařovat.</b> Pokud je tohle váš případ, vyzkoušejte{" "}
                <a href="https://nutritionpro.cz/" target="_blank">
                  naše krabičky.
                </a>{" "}
                Jídlo za vás <b>vybereme, uvaříme a zdarma doručíme</b> až k
                vašim dveřím. Také vypočítáme váš optimální energetický příjem
                podle programu, který si sami zvolíte. Nemusíte se tak bát, že
                by vám chyběla energie, bílkoviny nebo vitaminy a minerální
                látky. Pokud vás trápí i něco jiného, s námi budete mít vždy k
                ruce <b>nutriční terapeutku,</b> se kterou můžete vše probrat.
              </p>
              <h5 className={styles.postTitle}>
                Máte špatně nastavený jídelníček
              </h5>
              <p className={styles.postText}>
                Jednou ze základních chyb při hubnutí je špatně nastavený
                jídelníček, tedy výběr nevhodných potravin. Váš jídelníček by
                měl vždy obsahovat{" "}
                <b>
                  dostatek čerstvého ovoce a zeleniny, celozrnné výrobky,
                  luštěniny a také maso, ryby a mléčné výrobky.
                </b>{" "}
                Neopomíjejte ani klasické přílohy, jako jsou{" "}
                <b>rýže, brambory a těstoviny.</b>Dbejte na <b>kvalitu</b>{" "}
                potravin a dávejte <b>pozor na tepelnou úpravu.</b> Čas od času
                si také dejte něco dobrého, na co máte zrovna chuť. Zdravý
                jídelníček má být <b>vyvážený.</b> Pokud stále nevíte, jak byste
                měli svá jídla poskládat, nechte si pomoci od nás,{" "}
                <a href="https://nutritionpro.cz/" target="_blank">
                  objednejte si krabičky
                </a>{" "}
                a mějte jistotu, že je váš jídelníček nastaven správně.
              </p>
              <h5 className={styles.postTitle}>
                Nemáte dostatek fyzické aktivity
              </h5>
              <p className={styles.postText}>
                Se správnou stravou jde ruku v ruce i dostatek fyzické aktivity,
                který vám <b>pomůže nejen zhubnout,</b>ale i vést lepší a
                kvalitnější život. Tím, že se začnete více hýbat, tak přispějete
                nejen své postavě, ale i svému <b>zdraví.</b> Nemusíte se dřít v
                posilovně ani uběhnout maraton.{" "}
                <b>Stačí dělat to, co vás baví.</b> Zařaďte také{" "}
                <b>co nejvíce přirozeného pohybu.</b> Do práce jeďte veřejnou
                dopravou, na kole, nebo jděte pěšky. Vycházejte schody, choďte
                na procházky, cokoliv, <b>hlavně se hýbejte.</b>
              </p>

              <h5 className={styles.postTitle}>Málo spíte</h5>
              <p className={styles.postText}>
                Spánek je velmi důležitým faktorem co se hubnutí týče. Ideální
                doba spánku by se měla pohybovat <b>okolo 8 hodin</b>a neměla by
                spadnout pod 6. Pokud nebudete spát dostatečně, bude mít vaše
                tělo <b>potřebu dohnat energii,</b> kterou nemohlo spánkem
                získat. Budete pak mít <b>větší hlad a větší chutě,</b> logicky
                toho tedy i víc sníte. Raději si najděte čas a pořádně se
                vyspěte.
              </p>

              <h5 className={styles.postTitle}>Špatně nastavená mysl</h5>
              <p className={styles.postText}>
                Pokud se neustále bičujete, nevěříte si nebo dokonce{" "}
                <b>nemáte své tělo rádi,</b> těžko se vám s ním bude pracovat.
                Zkuste si uvědomit, že jsme všichni <b>jedineční</b> a měli
                bychom si vážit toho, co máme. <b>Mějte své tělo rádi</b> a
                dělejte vše pro to, aby bylo <b>zdravé</b> a mohli jste tu díky
                němu vydržet co nejdéle. Pokud mu budete dopřávat výživu a péči,
                kterou si zaslouží, <b>začne se měnit samo.</b> Důvodem, proč
                chcete zhubnout, by totiž{" "}
                <b>neměl být především vzhled, ale vaše zdraví.</b> Až změníte
                způsob, jakým sami sebe vnímáte, začnou se teprve dít věci.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default ProcSeVamNedariZhubnout
